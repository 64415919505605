import React, { useState } from 'react';
import { message, Alert, Button, Modal, Form, Input } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';

export default function AccountDeletion({ user }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const submitAccountDeletionRequest = (values) => {
    setLoading(true);
    const { cloud_name, customer_stripe, email, status, uid } = user;
    const { reason } = values;
    let userObj = {
      cloud_name,
      email,
      status: status[0],
      uid,
      message: reason,
      stripe_cid: '',
      stripe_sub_id: '',
    };
    if (customer_stripe !== null) {
      userObj.stripe_cid = customer_stripe.customer;
      userObj.stripe_sub_id = customer_stripe.subscription;
    }
    var data = JSON.stringify([userObj]);

    var config = {
      method: 'post',
      url: `${process.env.GATSBY_BASE_URL}/betauser/airtable/WerXWBFVfhAmgDzk?tableName=AccountDeletion`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        form.resetFields();
        setIsModalVisible(false);
        setLoading(false);
        message.info(
          'We will process your request within 24 hours & you will get the confirmation email after that.',
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <>
      <p>
        <b>
          <u>Account Deletion Request</u>
        </b>
      </p>
      <Alert
        message='Delete all my account data and API
                                          information.'
        type='error'
        style={{ fontWeight: 300 }}
        action={
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={showModal}
            style={{
              fontSize: 13,
              fontWeight: 400,
            }}
          >
            Delete Account
          </Button>
        }
      />
      <Modal
        title='Are you absolutely sure?'
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <p>
          This action <b>cannot</b> be undone. This will permanently delete your all <b>APIs</b> &{' '}
          <b>account data</b>.
        </p>
        <Form
          form={form}
          name='basic'
          initialValues={{ remember: false }}
          onFinish={submitAccountDeletionRequest}
          autoComplete='off'
          layout='vertical'
        >
          <Form.Item name='reason' label='Please tell us, why you doing this?'>
            <Input.TextArea required />
          </Form.Item>

          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              loading={loading}
              style={{
                fontSize: 13,
                fontWeight: 400,
              }}
            >
              Request for deletion
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
